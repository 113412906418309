exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "", ""]);

// Exports
exports.locals = {
	"blue": "#039be5",
	"green": "#33cb83",
	"orange": "#ff9800",
	"red": "#ff5257",
	"darkBlue": "#003d7d",
	"blackBlue": "#0c2140",
	"lightOrange": "#fabb3c",
	"grey": "#606060",
	"lightGrey": "#cdcdcd",
	"darkWhite": "#f8f8f8",
	"white": "#fff",
	"black": "#252525",
	"yellow": "#f0e91d",
	"violet": "#6f7dce",
	"vinous": "#556080",
	"driving": "#33cb83",
	"unknown": "#979797",
	"parked": "#ff5257",
	"parked_with_alert": "#ff5257",
	"idling": "#f0e91d",
	"waiting_for_data": "#ff9800",
	"ping": "#69ed17",
	"speeding": "#039be5",
	"unplugged": "#cdcdcd",
	"harshAccelerationCount": "#a2c347",
	"harshBrakingCount": "#ff5257",
	"harshTurnCount": "#ff9800",
	"idleTime": "#ff9800",
	"driveTime": "#039be5",
	"phoneCallCount": "#039be5",
	"phoneUsageCount": "#ff9800",
	"speedingDistance": "#ff9800",
	"totalDistance": "#039be5",
	"maintenanceCount": "#fabb3c",
	"diagnosticCount": "#003d7d",
	"tripCount": "#039be5",
	"harshEvents": "#ff5257",
	"healthAlerts": "#5a2dfc",
	"distracted": "#039be5",
	"vehicleInactivity": "#ff9800",
	"driverInactivity": "#ff9800",
	"day1": "#ff9800",
	"day7": "#039be5",
	"day15": "#fabb3c",
	"day30": "#5a2dfc"
};